
<!--系统参数配置-->

<template>

</template>

<script>
export default {
  name: "systemParams"
}
</script>

<style scoped>

</style>
